<template>
  <div class="outer">
    <div class="hl-header">
      <div class="hl-total">
        <div class="hotel-total">
          <div class="infor">
            <span>酒店统计</span>
            <span>{{ this.chart.hotelCount }}户</span>
          </div>
          <div style="display: flex; justify-content: space-around">
            <div id="jdxz" :style="{ width: '200px', height: '200px' }"></div>
            <div id="jdxz1" :style="{ width: '200px', height: '200px' }"></div>
          </div>
        </div>
        <div class="order-total">
          <div class="infor">
            <span>订单统计</span>
            <span>{{ this.chart.revCount }}单</span>
          </div>
          <div style="display: flex; justify-content: space-around">
            <!-- 今日预定订单 -->
            <div id="jryd" :style="{ width: '200px', height: '200px' }"></div>
            <!-- 本月预定订单 -->
            <div id="byyd" :style="{ width: '200px', height: '200px' }"></div>
            <!-- 今日在线登记 -->
            <div id="zxdj" :style="{ width: '200px', height: '200px' }"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="hl-table">
      <div class="infor1">
        <span>酒店信息</span>
      </div>
      <el-table
        :data="tableData"
        height="90%"
        
        style="width: 98%"
        :header-cell-style="{ background: '#F4F5F9', color: '#000000' }"
      >
        <el-table-column label="酒店名称" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="酒店地址">
          <template slot-scope="scope">
            <span>{{ scope.row.address }}</span>
          </template>
        </el-table-column>
        <el-table-column label="负责人姓名" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.linkName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="负责人电话" width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.linkPhone }}</span>
          </template>
        </el-table-column>

        <el-table-column label="有效期" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.validityTime }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status == 0">正常</span>
            <span v-else>停用</span>
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              class="cz-button"
              size="mini"
              @click="salary(scope.$index, scope.row)"
              >工资管理</el-button
            >
            <el-button
              class="cz-button"
              size="mini"
              @click="edit(scope.$index, scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>-->
      </el-table>
      <div class="addMore" @click="loadmore">
        <span>加载更多</span>
      </div>
    </div>

    <!-- <div class="block">
      <el-pagination
        @size-change="onPaginationSize"
        @current-change="onPaginationIndex"
        :current-page="condition.page"
        :page-size="condition.num"
        layout="prev, pager, next"
        :total="totolNums"
      ></el-pagination>
    </div>-->
    <!-- <addModify ref="addModify" /> -->
  </div>
</template>
<script>
// import { querySelectAllUser } from "@/api/api2.js";
// import addModify from "@/views/groupAccount/addModify.vue";
import { getRequest, postRequest } from "@/api/api.js";
export default {
  data() {
    return {
      tableData: [], //酒店信息数据
      chart: [], //饼图数据
      list: [],
      input: "",
      totolNums: null,
      condition: {
        offset: 1,
        num: 5,
      },
    };
  },
  components: {
    // addModify,
  },
  created() {
    // if (!this.power.checkedpower("00101")) {
    //   //在PMS管理系统里面-获取权限
    //   this.$router.push("power");
    // }
    this.getFounderList();
    this.selectAllHotelInfo();
  },
  mounted() {},

  methods: {
    //今日增长
    jdxz() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("jdxz"));
      // 绘制图表
      myChart.setOption({
        title: {
          text: this.chart.hotelDayCount + "\n\n今日创建",
          textStyle: {
            color: "#000000",
            fontSize: 12,
          },

          x: "center", //文字位置
          y: "center", //文字位置
        },
        series: [
          {
            name: "Access From5",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            color: ["#826AF9", "#F0F2F5"],
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                // fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [{ value: this.chart.hotelDayCount }, { name: "今日" }],
          },
        ],
      });
    },
    // 本月新增
    jdxz1() {
      // 基于准备好的dom，初始化echarts实例
      let myChart1 = this.$echarts.init(document.getElementById("jdxz1"));
      // 绘制图表
      myChart1.setOption({
        title: {
          text: this.chart.hotelMonthCount + "\n\n本月创建",
          textStyle: {
            color: "#000000",
            fontSize: 12,
          },

          x: "center", //文字位置
          y: "center", //文字位置
        },
        series: [
          {
            name: "Access From1",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            color: ["#2CD9C5"],
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                // fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [{ value: this.chart.hotelMonthCount }, { name: "本月" }],
          },
        ],
      });
    },
    // 今日预定订单
    jryd() {
      // 基于准备好的dom，初始化echarts实例
      let myChart1 = this.$echarts.init(document.getElementById("jryd"));
      // 绘制图表
      myChart1.setOption({
        title: {
          text: this.chart.revDayCount + "\n\n今日预订",
          textStyle: {
            color: "#000000",
            fontSize: 12,
          },

          x: "center", //文字位置
          y: "center", //文字位置
        },
        series: [
          {
            name: "Access From2",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            color: ["#1890FF"],
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "10",
                // fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [{ value: this.chart.revDayCount }],
          },
        ],
      });
    },
    // 本月预定订单
    byyd() {
      // 基于准备好的dom，初始化echarts实例
      let myChart1 = this.$echarts.init(document.getElementById("byyd"));
      // 绘制图表
      myChart1.setOption({
        title: {
          text: this.chart.revMonthCount + "\n\n本月预订",
          textStyle: {
            color: "#000000",
            fontSize: 12,
          },

          x: "center", //文字位置
          y: "center", //文字位置
        },
        series: [
          {
            name: "Access From3",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            color: ["#FFE700"],
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                // fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [{ value: this.chart.revMonthCount }],
          },
        ],
      });
    },
    // 今日在线登记
    zxdj() {
      // 基于准备好的dom，初始化echarts实例
      let myChart1 = this.$echarts.init(document.getElementById("zxdj"));
      // 绘制图表
      myChart1.setOption({
        title: {
          text: this.chart.checkDayCount + "\n\n今日登记",
          textStyle: {
            color: "#000000",
            fontSize: 12,
          },

          x: "center", //文字位置
          y: "center", //文字位置
        },
        series: [
          {
            name: "Access From4",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            color: ["#FF6C40"],
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                // fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [{ value: this.chart.checkDayCount }],
          },
        ],
      });
    },
    // 获取饼图数据
    getFounderList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllSysQuantity", this.condition).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.chart = res.data.data[0];
          this.jdxz();
          this.jdxz1();
          this.jryd();
          this.byyd();
          this.zxdj();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 获取酒店管理列表
    selectAllHotelInfo() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotelInfo", this.condition).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.tableData = res.data.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 加载更多
    loadmore() {
      this.$router.push("/jiudianguanli");
      // window.location.href = "/jiudianguanli";
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.echart {
  width: 148px;
  height: 148px;
  background: #fff;
}
.outer {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  width: 60px;
  height: 30px;
  /* background:rgb(163, 197, 248) ; */
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 26px;
}
.hl-total {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 40px;
  height: 276px;
}
.hotel-total {
  background-color: #fff;
  flex: 2;
  margin-right: 24px;
}
.order-total {
  background-color: #fff;
  flex: 3;
}

.addIdType {
  width: 80px;
  /* margin-left: 100px; */
}
.hl-table {
  flex: 1;
  background: #fff;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cz-button {
  height: 30px;
  width: 60px;
  background-color: #fff;
}
.infor {
  width: 96%;
  height: 55px;
  border-bottom: 1px solid #e8ecef;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  justify-content: space-between;
  padding: 2%;
}
.infor1 {
  width: 98%;
  height: 55px;
  border-bottom: 1px solid #e8ecef;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  justify-content: space-between;
  padding: 1%;
}
.infor span {
  color: rgba(0, 0, 0, 0.85);
}
.el-table {
  /* margin: 0 32px; */
}
.addMore {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  height: 64px;
  cursor: pointer;
}
.addMore > span {
  border: 1px solid #e8ecef;
  width: 153px;
  text-align: center;
  height: 32px;
  line-height: 32px;
  display: inline-block;
}
/* .el-menu-item-group {
  background: #ebf2ff;
  border-left: 1px solid #387DFF;
} */
</style>